export enum BackgroundType {
	Image = 'image',
	Color = 'color',
}

export const DefaultCardBackground =
	'linear-gradient(94deg, #42CB91 1.12%, #00AFC6 26.23%, #2097E2 47.33%, #338BF2 62.39%, #487CED 72.44%, #8056E3 92.53%, #934AE0 98.55%)'

export function getBackgroundType(background: string): BackgroundType {
	if (
		background.startsWith('url') ||
		background.startsWith('linear-gradient')
	) {
		return BackgroundType.Image
	}
	return BackgroundType.Color
}
